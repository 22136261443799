import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import DangerBox from 'components/Internet_Access/Mobile_Access/DangerBox';
import NavButtons from 'components/Internet_Access/NavButtons';
import ForumBox from 'components/Internet_Access/Mobile_Access/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Mobile Access",
  "path": "/Internet_Access/Mobile_Access/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Access your camera from your smartphone",
  "image": "./IA_SearchThumb_P2P.png",
  "social": "/images/Search/IA_SearchThumb_Mobile.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-Mobile_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Mobile Access' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Access your camera from your smartphone' image='/images/Search/IA_SearchThumb_Mobile.png' twitter='/images/Search/IA_SearchThumb_Mobile.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/Mobil_Zugriff/' locationFR='/fr/Internet_Access/Mobile_Access/' crumbLabel="Mobile Access" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "mobile-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#mobile-access",
        "aria-label": "mobile access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mobile Access`}</h1>
    <h2 {...{
      "id": "iii-access-your-camera-through-its-ddns-address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iii-access-your-camera-through-its-ddns-address",
        "aria-label": "iii access your camera through its ddns address permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`III. Access your Camera through its DDNS Address`}</h2>
    <p>{`Please read `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`Step I`}</a>{` on how to configure your camera and `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Step II`}</a>{` for the necessary router configuration first.`}</p>
    <p>{`In the following we will use the web browser as well as the InstarVision smartphone app to access the INSTAR IP camera remotely.`}</p>
    <p>{`Please open your camera´s Web User Interface and navigate to `}<strong parentName="p">{`Network / DDNS`}</strong>{` (`}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/Remote_Access/"
      }}>{`1080p Series`}</a>{` / `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Network/DDNS/"
      }}>{`720p Series`}</a>{`). Copy your personal DDNS address into your browser´s address bar and add your camera´s external port that you forwarded in `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Step II`}</a>{` - e.g. `}<em parentName="p">{`test12.ddns3-instar.de:8081`}</em>{`. This address will guide you to your camera´s user interface. `}</p>
    <p><strong parentName="p">{`Some routers don´t allow you to use your camera´s DDNS address from inside your local network!`}</strong>{`
(e.g. Telekom Speeport)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "320px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c3eb569c2357d509ac26c28ecdabdd9/72799/ddns_address_in_browser.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAABFElEQVQY01WNy07CQABF+/9/4sbEBWGjJiQmxiAg1JaZvqaPoTNteYoaFOQYii5YnNyTm5tcp+r3WUxvCdMuvbRHVVZkeUpWGuq6pjQG88fZLab87yxN02CsRWtNoTVOeH+Herpm5F7RmdyQxCnyscvwocNqtcL3XKJQouIQMfVJ4gjhe4ip1/rbZk1TVwRSMBw84yitSXKLF9TIwCLTisKuyXVDUW/JzLpF11tyu6GoNm2q2ZJYz4mLOWq2QCQGV+Y45WyGNYbP3Z733YFEFbxOY0QQM3YlQZQSJBmeiFofjn0GI5fl5oPDEb4OZ75/YH8EJ4oiVJq1D1JZJiLlxVdtjrwEV2RMZHbRnfy0j/KaMLvkF6wkaka5PdFeAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c3eb569c2357d509ac26c28ecdabdd9/e4706/ddns_address_in_browser.avif 230w", "/en/static/4c3eb569c2357d509ac26c28ecdabdd9/297e5/ddns_address_in_browser.avif 320w"],
              "sizes": "(max-width: 320px) 100vw, 320px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c3eb569c2357d509ac26c28ecdabdd9/a0b58/ddns_address_in_browser.webp 230w", "/en/static/4c3eb569c2357d509ac26c28ecdabdd9/4c812/ddns_address_in_browser.webp 320w"],
              "sizes": "(max-width: 320px) 100vw, 320px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c3eb569c2357d509ac26c28ecdabdd9/81c8e/ddns_address_in_browser.png 230w", "/en/static/4c3eb569c2357d509ac26c28ecdabdd9/72799/ddns_address_in_browser.png 320w"],
              "sizes": "(max-width: 320px) 100vw, 320px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c3eb569c2357d509ac26c28ecdabdd9/72799/ddns_address_in_browser.png",
              "alt": "Portforwarding Vodafone EasyBox",
              "title": "Portforwarding Vodafone EasyBox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To access your camera, you can always use the same DDNS address. So if you have more than one camera you only need to remember one address. Most important is that you change the port behind the DDNS number. The reason behind it is that your internet service provider is assigning you a new (WAN) IP address every 24 hours. Once the address is changed the camera notices this and updates your DDNS address. This way you can always access your camera with one address - even so the WAN IP behind it changes. All DDNS addresses from cameras inside the same local network all link to one address - the WAN IP that your ISP assigns to your router. So with every address you will reach your router. And because of the port forwarding your router then knows to which device you want to be forwarded to. So for example we type the following to access the cameras:`}</p>
    <ul>
      <li parentName="ul">{`Camera 1: `}<a parentName="li" {...{
          "href": "http://d2908.ddns-instar.com:8081"
        }}>{`http://d2908.ddns-instar.com:8081`}</a></li>
      <li parentName="ul">{`Camera 2: `}<a parentName="li" {...{
          "href": "http://d2908.ddns-instar.com:8082"
        }}>{`http://d2908.ddns-instar.com:8082`}</a></li>
      <li parentName="ul">{`Camera 3: `}<a parentName="li" {...{
          "href": "http://d2908.ddns-instar.com:8082"
        }}>{`http://d2908.ddns-instar.com:8082`}</a>{` and so on...`}</li>
    </ul>
    <p><strong parentName="p">{`Remark`}</strong>{`: It can take up to half an hour for your DDNS address to activate. If your connection fails, wait for 30min (restart your router as it might have cached the earlier failed attempt) and then try again.`}</p>
    <OutboundLink href="https://www.instar.com/support/help-tools" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink"><EuiButton fill mdxType="EuiButton">Bandwidth Calculator</EuiButton></OutboundLink>
    <h2 {...{
      "id": "instarvision-mobile-apps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instarvision-mobile-apps",
        "aria-label": "instarvision mobile apps permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InstarVision Mobile Apps`}</h2>
    <p>{`To add your camera to our free mobile app InstarVision, use the network scanner to find cameras on your local network. Or open the DDNS menu (`}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/Remote_Access/"
      }}>{`1080p Series`}</a>{` / `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Network/DDNS/"
      }}>{`720p Series`}</a>{`) to scan your camera´s QRcode:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Windows_Phone/DDNS/"
        }}>{`Windows Phone`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Metro/DDNS/"
        }}>{`Windows 8/10 (Metro)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPhone/DDNS/"
        }}>{`iPhone`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`iPad`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Android/InstarVision/DDNS/"
        }}>{`Android`}</a></li>
    </ul>
    <h3 {...{
      "id": "instarvision-2-for-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#instarvision-2-for-windows",
        "aria-label": "instarvision 2 for windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InstarVision 2 for Windows`}</h3>
    <p>{`If you are using a Windows Tablet or Laptop, check out our `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Camera_List/"
      }}>{`User Manual`}</a>{` for how to add your INSTAR IP camera to our Windows Software InstarVision 2.`}</p>
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`It is not possible to use the DDNS Service if you don´t have a public IPv4 address. If you only have an IPv6-address, you can ask your provider to give you an IPv4-address. Or use our P2P SERVICE instead of the DDNS address.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      